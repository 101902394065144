exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agbs-js": () => import("./../../../src/pages/agbs.js" /* webpackChunkName: "component---src-pages-agbs-js" */),
  "component---src-pages-archiv-2024-index-js": () => import("./../../../src/pages/archiv/2024/index.js" /* webpackChunkName: "component---src-pages-archiv-2024-index-js" */),
  "component---src-pages-archiv-js": () => import("./../../../src/pages/archiv.js" /* webpackChunkName: "component---src-pages-archiv-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalender-js": () => import("./../../../src/pages/kalender.js" /* webpackChunkName: "component---src-pages-kalender-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partnerinnen-js": () => import("./../../../src/pages/partnerinnen.js" /* webpackChunkName: "component---src-pages-partnerinnen-js" */),
  "component---src-pages-presse-js": () => import("./../../../src/pages/presse.js" /* webpackChunkName: "component---src-pages-presse-js" */),
  "component---src-pages-programm-js": () => import("./../../../src/pages/programm.js" /* webpackChunkName: "component---src-pages-programm-js" */),
  "component---src-pages-tickets-js": () => import("./../../../src/pages/tickets.js" /* webpackChunkName: "component---src-pages-tickets-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-projekt-24-js": () => import("./../../../src/templates/projekt24.js" /* webpackChunkName: "component---src-templates-projekt-24-js" */),
  "component---src-templates-projekt-js": () => import("./../../../src/templates/projekt.js" /* webpackChunkName: "component---src-templates-projekt-js" */)
}

